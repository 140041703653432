// 'use strict';;
import React from 'react';
// var toPdf = require("office-to-pdf")
// var fs = require("fs")
// var wordBuffer = fs.readFileSync("./test.docx")
class WordToDocx extends React.Component{


    state={
        selectedFile:null,
    }

    componentDidMount(){
        
    }
    
    textToSlug(defaults){
        let wordArr=(defaults.trim()).split(' ');
        let data='';
        wordArr.forEach(e=>{
            if(e!=''){
                data+=e.toLowerCase()+'-';
            }
           })
        this.state.words=(data.slice(0,data.length-1)).replace(/(\r\n|\n|\r)/gm, "");;
        this.forceUpdate();
    }
    handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`; 
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
      }
      onFileChange = event => {
    
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
        console.log(event.target.files[0]);
      };
    render(){
        return (
            <div className='container'>
                {/* <div className="banner-title">
                    Text Case Conversion Tools
                </div> */}
                <section class="wrapper bg-soft-primary">
                    <div class="container pt-5 pb-10 pt-md-14 pb-md-10 text-center">
                        <div class="row">
                        <div class="col-md-9 col-lg-7 col-xl-5 mx-auto">
                            <h1 class="display-1 mb-3">Text To Slug Convert</h1>
                            <p class="lead px-xxl-10">Copy any custom Text and paste below .</p>
                        </div>
                        </div>
                    </div>
                </section>
                <div className="row mt-5 mb-5">
                    <div className="col-12 col-md-6">
                        <form action="">
                            <input type='file' name="" id="wordstodoc" className="form-control" placeholder="write something..." onChange={this.onFileChange}/>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

}
export default WordToDocx