import React from "react";
import {Link} from 'react-router-dom'

const SideButton=(props)=>{


    return (
        <div class="btn-group-vertical d-block " role="group" aria-label="Vertical button group">
        
        <button type="button" class="btn btn-light text-primary" style={{border:'1px solid'}}><Link to='/decimal-to-binary'>Decimal to Binnary</Link></button>
        <button type="button" class="btn btn-light text-primary" style={{border:'1px solid'}}>Binary To Decimal</button>
        <button type="button" class="btn btn-light text-primary" style={{border:'1px solid'}}>Hex to Decimal</button>
        <button type="button" class="btn btn-light text-primary" style={{border:'1px solid'}}>Hex to Binary</button>
        <button type="button" class="btn btn-light text-primary" style={{border:'1px solid'}}>Ascii to Binary</button>
        <button type="button" class="btn btn-light text-primary" style={{border:'1px solid'}}>Text to Slug</button>
        </div>
    )
}

export default SideButton