// 'use strict';;
import React from 'react';
// var toPdf = require("office-to-pdf")
// var fs = require("fs")
// var wordBuffer = fs.readFileSync("./test.docx")
import TxtBinary from 'decode-encode-binary';
import CopyButton from '../copybutton/copybutton'
import SideButton from '../layouts/textboxes/sidebuttons';
import {Helmet} from 'react-helmet';
import {metaTag } from '../../meta';
class BinaryToText extends React.Component{


    state={
        selectedFile:null,
        words:'',
        defaults:'',
    }

    componentDidMount(){
        
    }
    
    textToSlug(defaults){
        if(defaults!=''){
            let text= TxtBinary.decode(defaults,true);
            this.state.words=text.toString();
            console.log(text)
            this.forceUpdate();
        }
    }
    handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`; 
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    }
    onFileChange = event => {
    
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
        console.log(event.target.files[0]);
    };
    textCopy(text){
        navigator.clipboard.writeText(text);
    }
    render(){
        return (
            <div className='container'>
                {/* <div className="banner-title">
                    Text Case Conversion Tools
                </div> */}
                <Helmet>
                    <title>{metaTag.binary_to_text.title}</title>
                    <meta name="description" content={metaTag.binary_to_text.description} />
                    <meta name="keywords" content={metaTag.binary_to_text.keyword} />
                    {/* meta for facebook */}
                    <meta property="og:locale"  content={metaTag.binary_to_text.meta.facebook.locale}/>
                    <meta property="og:type"  content={metaTag.binary_to_text.meta.facebook.type}/>
                    <meta property="og:title"  content={metaTag.binary_to_text.meta.facebook.title}/>
                    <meta property="og:description"  content={metaTag.binary_to_text.meta.facebook.description}/>
                    <meta property="og:url"  content={metaTag.binary_to_text.meta.facebook.url}/>
                    <meta property="og:site_name"  content={metaTag.binary_to_text.meta.facebook.site_name}/>
                    <meta property="og:image"  content={metaTag.binary_to_text.meta.facebook.image}/>
                    <meta property="og:image:width"  content={metaTag.binary_to_text.meta.facebook.image_width}/>
                    <meta property="og:image:height"  content={metaTag.binary_to_text.meta.facebook.image_height}/>
                    <meta property="og:image:alt"  content={metaTag.binary_to_text.meta.facebook.image_alt}/>
                    <meta property="og:image:type"  content={metaTag.binary_to_text.meta.facebook.image_type}/>
                    {/* meta for twitter */}
                    <meta name="twitter:card" content={metaTag.binary_to_text.meta.twitter.card} />
                    <meta name="twitter:title" content={metaTag.binary_to_text.meta.twitter.title} />
                    <meta name="twitter:description" content={metaTag.binary_to_text.meta.twitter.description} />
                    <meta name="twitter:image" content={metaTag.binary_to_text.meta.twitter.image} />
                    <meta name="twitter:url" content={metaTag.binary_to_text.meta.twitter.url} />
                    <meta name="twitter:site" content={metaTag.binary_to_text.meta.twitter.site} />
                    <meta name="twitter:creator" content={metaTag.binary_to_text.meta.twitter.creator} />
                </Helmet>
                <section class="wrapper bg-soft-primary">
                    <div class="container pt-5 pb-10 pt-md-14 pb-md-10 text-center">
                        <div class="row">
                        <div class="col-md-9 col-lg-7 col-xl-5 mx-auto">
                            <h1 class="display-1 mb-3">Binary To Text</h1>
                            <p class="lead px-xxl-10">Write any custom Binary Digit and convert to Text</p>
                        </div>
                        </div>
                    </div>
                </section>
                <div className="row mt-5 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row ">
                            <div className="col-12 col-md-12">
                                <form action="">
                                    <textarea name="" id="words"  rows="5" className="form-control" placeholder="write something..." onChange={(e)=>{this.setState({defaults:e.target.value})}} onKeyDown={this.handleKeyDown}></textarea>
                                </form>
                                <div className="counter">word: {(this.state.defaults !='' ? this.state.defaults.split(" ").length :0)} | Length: {this.state.defaults.length}</div>
                                <div>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1" onClick={()=>this.textToSlug(this.state.defaults)}>Convert</button>
                                </div>
                            </div>
                            <CopyButton words={this.state.words}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <SideButton/>
                    </div>
                </div>
                
            </div>
        )
    }

}
export default BinaryToText