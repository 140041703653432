import React,{  useEffect,useState }  from "react";
import parse from 'html-react-parser'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
const CopyButton=(props)=>{
    const [visible, setVisible] = useState(false);
    let show = () => setVisible(true);
    let hide = () => setVisible(false);
    const textCopy=(text)=>{
        navigator.clipboard.writeText(text);
        
    }


    return (
        <>
            
            <div className="col-12 col-md-12  my-3" style={{textAlign:'justify'}}>
                <div className='border p-1' style={{minHeight:'100px'}}>
                <p className="">
                    <div style={{float:'right'}}>
                        <Tippy   trigger='click'  placement="right"  content={<span>Copied</span>}>
                            <button onMouseLeave={()=> setVisible(false)} className="btn btn-secondary  btn-sm p-1 pt-0 pb-0 " onClick={textCopy(props.words)}><i style={{fontSize:'15px'}} className="fas fa-copy"></i></button>
                        </Tippy>
                    </div>
                {parse(props.words.replace(/\n\r?/g, '<br />'))}
                </p>
                </div>
            </div>
            
        </>
    )
}

export default CopyButton