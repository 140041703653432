import React,{Component} from "react";


class Footer extends React.Component{




    render(){
        return (
        <footer class="bg-dark text-inverse">
          <div class="container py-13 py-md-15">
            <div class="row gy-6 gy-lg-0">
              <div class="col-md-4 col-lg-3">
                <div class="widget">
                  <img class="mb-4" src="./assets/img/logo-light.png" srcset="./assets/img/logo-light@2x.png 2x" alt="" />
                  <p class="mb-4">&copy; {(new Date()).getFullYear()} All rights reserved.</p>
                  <nav class="nav social social-white">
                    <a href="https://www.facebook.com/ongsho"><i class="uil uil-facebook-f"></i></a>
                    <a href="https://www.twitter.com/ongsho"><i class="uil uil-twitter"></i></a>
                    <a href="https://www.youtube.com/ongsho"><i class="uil uil-youtube"></i></a>
                    <a href="https://www.linkedin.com/company/ongsho"><i class="uil uil-linkedin"></i></a>
                  </nav>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="widget">
                  <h4 class="widget-title text-white mb-3">Others Products</h4>
                  <ul class="list-unstyled  mb-0">
                    
                    <li><a href="#">Support</a></li>
                    <li><a href="#">FAQ's</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-lg-3">
                <div class="widget">
                  <h4 class="widget-title text-white mb-3">Learn More</h4>
                  <ul class="list-unstyled  mb-0">
                    
                    <li><a href="#">Terms of Use</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-12 col-lg-3">
                <div class="widget">
                  <h4 class="widget-title text-white mb-3">Our Newsletter</h4>
                  
                  <div class="newsletter-wrapper">
                    <div id="mc_embed_signup2">
                      <form action="https://elemisfreebies.us20.list-manage.com/subscribe/post?u=aa4947f70a475ce162057838d&amp;id=b49ef47a9a" method="post" id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form" class="validate dark-fields" target="_blank" novalidate>
                        <div id="mc_embed_signup_scroll2">
                          <div class="mc-field-group input-group form-floating">
                            <input type="email" value="" name="EMAIL" class="required email form-control" placeholder="Email Address" id="mce-EMAIL2"/>
                            <label for="mce-EMAIL2">Email Address</label>
                            <input type="submit" value="Join" name="subscribe" id="mc-embedded-subscribe2" class="btn btn-primary"/>
                          </div>
                          <div id="mce-responses2" class="clear">
                            <div class="response" id="mce-error-response2" style={{display:'none'}}></div>
                            <div class="response" id="mce-success-response2" style={{display:'none'}}></div>
                          </div> 
                          <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabindex="-1" value=""/></div>
                          <div class="clear"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        )
    }
}

export default Footer
