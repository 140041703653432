export const metaTag=
    { 
        'title':"Ongsho Tools",
        'description':"Ongsho Tools",
        'keyword':"Ongsho Tools",
        'ascii_to_binary':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'ascii_to_text':{
            title:"Ascii To Text - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Text - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'binary_to_ascii':{
            title:"Binary to Ascii - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'binary_to_decimal':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'binary_to_hex':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'binary_to_text':{
            title:"Binary to Text - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'case_converter':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'decimal_to_binary':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'hex_to_binary':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'lorem_generator':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'text_to_ascii':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'text_to_slug':{
            title:"Ascii To Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },
        'text_to_binary':{
            title:"Text to Binary - Ongsho Tools",
            description:'convert your ascii text to binary online with in short time for your document',
            keyword:"Ascii to binary,converter,online convert,ongsho,ongsho tools,tools.ongsho.com",
            meta:{
                facebook:{
                    locale:"en_US",
                    type:"website",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    site_name:"Ongsho Tools",
                    image:"",
                    image_width:"",
                    image_height:"",
                    image_alt:"",
                    image_type:"",
                },
                twitter:{
                    card:"summary_large_image",
                    site:"@ongsho",
                    creator:"@mohidulinfo",
                    url:"https://tools.ongsho.com/ascii-to-binary",
                    title:"Ascii To Binary - Ongsho Tools",
                    description:"convert your ascii text to binary online with in short time for your document",
                    image:"",
                    
                }
            }
        },

    }
