import React from 'react'
import {LoremIpsum} from 'lorem-ipsum'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CopyButton from '../copybutton/copybutton'
import SideButton from '../layouts/textboxes/sidebuttons';
import {Helmet} from 'react-helmet';
import {metaTag } from '../../meta';
class LoremGenerator extends React.Component{


    state={
        type:null,
        number:null,
        htmlMarkup:null,
        words:''
    }
    componentDidMount(){
  
    }
    loremGenerate(type,number,htmlType){
        console.log(type,parseInt(number),htmlType)
        const lorem = new LoremIpsum({
            sentencesPerParagraph: {
              max: 8,
              min: 1
            },
            wordsPerSentence: {
              max: 16,
              min: 1
            }
          });
          let words;

        console.log(lorem.generateWords(5));
        if(type=="paragraph"){
            words=lorem.generateParagraphs(parseInt(number));
        }else if(type=='sentences'){
            words=lorem.generateSentences(parseInt(number));
        }else if(type=='words'){
            words=lorem.generateWords(parseInt(number));
        }
        this.state.words=words;
        this.forceUpdate();
    }
    textCopy(text){
        navigator.clipboard.writeText(text);
    }
    render(){
        return (
            <div className='container'>
                {/* <div className="banner-title">
                    Text Case Conversion Tools
                </div> */}
                <Helmet>
                    <title>{metaTag.lorem_generator.title}</title>
                    <meta name="description" content={metaTag.lorem_generator.description} />
                    <meta name="keywords" content={metaTag.lorem_generator.keyword} />
                    {/* meta for facebook */}
                    <meta property="og:locale"  content={metaTag.lorem_generator.meta.facebook.locale}/>
                    <meta property="og:type"  content={metaTag.lorem_generator.meta.facebook.type}/>
                    <meta property="og:title"  content={metaTag.lorem_generator.meta.facebook.title}/>
                    <meta property="og:description"  content={metaTag.lorem_generator.meta.facebook.description}/>
                    <meta property="og:url"  content={metaTag.lorem_generator.meta.facebook.url}/>
                    <meta property="og:site_name"  content={metaTag.lorem_generator.meta.facebook.site_name}/>
                    <meta property="og:image"  content={metaTag.lorem_generator.meta.facebook.image}/>
                    <meta property="og:image:width"  content={metaTag.lorem_generator.meta.facebook.image_width}/>
                    <meta property="og:image:height"  content={metaTag.lorem_generator.meta.facebook.image_height}/>
                    <meta property="og:image:alt"  content={metaTag.lorem_generator.meta.facebook.image_alt}/>
                    <meta property="og:image:type"  content={metaTag.lorem_generator.meta.facebook.image_type}/>
                    {/* meta for twitter */}
                    <meta name="twitter:card" content={metaTag.lorem_generator.meta.twitter.card} />
                    <meta name="twitter:title" content={metaTag.lorem_generator.meta.twitter.title} />
                    <meta name="twitter:description" content={metaTag.lorem_generator.meta.twitter.description} />
                    <meta name="twitter:image" content={metaTag.lorem_generator.meta.twitter.image} />
                    <meta name="twitter:url" content={metaTag.lorem_generator.meta.twitter.url} />
                    <meta name="twitter:site" content={metaTag.lorem_generator.meta.twitter.site} />
                    <meta name="twitter:creator" content={metaTag.lorem_generator.meta.twitter.creator} />
                </Helmet>
                <section class="wrapper bg-soft-primary">
                    <div class="container pt-5 pb-10 pt-md-14 pb-md-10 text-center">
                        <div class="row">
                        <div class="col-md-9 col-lg-7 col-xl-5 mx-auto">
                            <h1 class="display-1 mb-3">Lorem Generator</h1>
                            <p class="lead px-xxl-10">Fill the Form below and Generate Lorem Ipsum word</p>
                        </div>
                        </div>
                    </div>
                </section>
                <div className="row mt-5 mb-5">
                    <div className="col-12 col-md-9">
                            <div className="row ">
                                <div className="col-12 col-md-12">
                                    <form action="">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="">Select Type</label>
                                                <select name="" id="" className="form-control" onChange={(e)=>{this.setState({type:e.target.value})}}>
                                                    <option value="">--select--</option>
                                                    <option value="paragraph">Paragraph</option>
                                                    <option value="words">Words</option>
                                                    <option value="sentences">Sentences</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                            <label htmlFor="">Number</label>
                                            <input type="text" placeholder="Number" className="form-control" onChange={(e)=>{this.setState({number:e.target.value})}} />
                                            </div>
                                        </div>
                                        {/* <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="">HTML Markup</label>
                                                <select name="" id="" className="form-control" onChange={(e)=>{this.setState({htmlMarkup:e.target.value})}}>
                                                    <option value="">--select--</option>
                                                    <option value="0">yes</option>
                                                    <option value="1">no</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </form>
                                    <div>
                                        <button className="btn btn-sm btn-primary mt-1 mb-1 mr-1" onClick={()=>this.loremGenerate(this.state.type,this.state.number,null)}>Generate</button>
                                    </div>
                                </div>
                                <CopyButton words={this.state.words}/>
                            
                            </div>
                    </div>
                    <div className="col-12 col-md-3 mt-5">
                        <SideButton/>
                    </div>
                </div>
                
            </div>
        )
    }
}
export default LoremGenerator