import logo from './logo.svg';
import './App.css';
import React from 'react'
import { Routes, Route, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import CaseConversion from "./components/case-converter/case-conversion";
import TextToSlug from "./components/text-to-slug/text-to-slug";
import LoremGenerator from "./components/lorem-generator/lorem-generator";
import WordToDocx from "./components/word_to_docx/word_to_docx";
import TextToBinary from "./components/text-to-binary/text-to-binary";
import BinaryToText from "./components/binary-to-text/binary-to-text";
import HexToBinary from "./components/hex-to-binary/hex-to-binary";
import BinaryToHex from "./components/binary-to-hex/binary-to-hex";
import AsciiToBinary from "./components/ascii-to-binary/ascii-to-binary";
import BinaryToAscii from "./components/binary-to-ascii/binary-to-ascii";
import DecimalToBinary from "./components/decimal-to-binary/decimal-to-binary";
import BinaryToDecimal from "./components/binary-to-decimal/binary-to-decimal";
import TextToAscii from "./components/text-to-ascii/text-to-ascii";
import AsciiToText from "./components/ascii-to-text/ascii-to-text";
import Home from "./components/home/home";
import Header from "./components/layouts/header";
import Footer from "./components/layouts/footer";
import { metaTag } from './meta';


class  App extends React.Component {
  
  render(){
    return (
      <>
      {/* <CaseConversion>
      </CaseConversion> */}
      {/* <TextToSlug>
      </TextToSlug> */}
      
      <Header/>
      <Helmet>
        <title>{metaTag.title}</title>
        <title>{metaTag.description}</title>
        
      </Helmet>
       <Routes>
           <Route path="/lorem-generator" element={<LoremGenerator />} />
           <Route path="/case-converter" element={<CaseConversion />} />
           <Route path="/text-to-slug" element={<TextToSlug />} />
           <Route path="/word-to-docx" element={<WordToDocx />} />
           <Route path="/text-to-binary" element={<TextToBinary />} />
           <Route path="/binary-to-text" element={<BinaryToText />} />
           <Route path="/hex-to-binary" element={<HexToBinary />} />
           <Route path="/binary-to-hex" element={<BinaryToHex />} />
           <Route path="/ascii-to-binary" element={<AsciiToBinary />} />
           <Route path="/binary-to-ascii" element={<BinaryToAscii />} />
           <Route path="/decimal-to-binary" element={<DecimalToBinary />} />
           <Route path="/binary-to-decimal" element={<BinaryToDecimal />} />
           <Route path="/text-to-ascii" element={<TextToAscii />} />
           <Route path="/ascii-to-text" element={<AsciiToText />} />
           <Route path="/" element={<Home />} />
       </Routes>
       <Footer/>
      </>
     );
  }
 
}

export default App;
