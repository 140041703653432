
// 'use strict';;
import React from 'react';
import { Helmet } from 'react-helmet';
import {Link} from "react-router-dom";
import TextToBinaryIcon from '../../images/text-to-binary.svg';
import Slider from './slider'
// var toPdf = require("office-to-pdf")
// var fs = require("fs")
// var wordBuffer = fs.readFileSync("./test.docx")
// import TxtBinary from 'hex-to-binary';
import {metaTag} from '../../meta';
class AsciiToBinary extends React.Component{
    state={
        selectedFile:null,
        words:'',
        defaults:'',
    }

    componentDidMount(){
        console.log(metaTag);
    }
    asciiToBinary(input) {
		var result = "";
		for (var i = 0; i < input.length; i++) {
			var bin = input[i].charCodeAt().toString(2);
			result += Array(8 - bin.length + 1).join("0") + bin;
		} 
		return result;
	}
    convert(defaults){
        if(defaults!=''){
            let text= this.asciiToBinary(defaults);
            this.state.words=text.toString();
            console.log(text)
            this.forceUpdate();
        }
    }
    handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`; 
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    }
    onFileChange = event => {
    
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
        console.log(event.target.files[0]);
    };
    textCopy(text){
        navigator.clipboard.writeText(text);
    }
    render(){
        return (
            <div className='container'>
                {/* <div className="banner-title">
                    Text Case Conversion Tools
                </div> */}
                <Slider/>
                           <h3 style={{lineHeight:'0.5'}} className='mt-5'>Content Tools</h3>
                           <p>A complete set of text tools is now at your fingertips. Create dummy text, count words, or change the text case.</p>
               
                           <div className="row pb-5">
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/case-converter" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Text to Ascii" /> Case Converter</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/text-to-slug" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Text-to-Slug" /> Text to Slug</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/lorem-generator" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Lorem Generator" /> Lorem Generator</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                           </div>
                           {/* Binary tools */}
                    <h3 style={{lineHeight:'0.5'}} className='mt-5'>Binary Converter Tools</h3>
                    <p>A collection of useful utilities for working with binary values.</p>
               
                          <div className="row pb-5">
                            <div class="col-12 col-md-3 col-lg-3 mb-3 ">
                                <div  className="card  cursor-pointer item-box">
                                <Link to="/text-to-binary" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Text To Binary" /> Text To Binary</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3 ">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/binary-to-text" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Binary To Text" /> Binary to Text</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/hex-to-binary" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Hex To Binary" /> Hex to Binary</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/binary-to-hex" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Binary to Hex" /> Binary to Hex</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/ascii-to-binary" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Ascii to Binary" /> Ascii to Binary</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/binary-to-ascii" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Binary to Ascii" /> Binary to Ascii</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/decimal-to-binary" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Decimal to Binary" /> Decimal to Binary</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/decimal-to-binary" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Decimal to Binary" /> Decimal to Binary</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/binary-to-decimal" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Binary to Decimal" /> Binary to Decimal</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/text-to-ascii" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Text to Ascii" /> Text to Ascii</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/case-converter" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Text to Ascii" /> Case Converter</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/text-to-slug" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Text-to-Slug" /> Text to Slug</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-lg-3 mb-3">
                                <div  className="card cursor-pointer item-box">
                                <Link to="/ascii-to-text" className="alio_green">
                                    <div  className="p-3  card-body border rounded">
                                        <div className="d-flex  justify-content-center">
                                            <div>
                                                <div style={{fontSize:'20px',fontWeight:'bold'}} class="font-weight-medium"><img  style={{width:'30px'}} src={TextToBinaryIcon} alt="Lorem Generator" /> Ascii to Text</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                </div>
                            </div>
                           </div>

                           {/*  */}
                           
                    
            </div>
        )
    }

}
export default AsciiToBinary