import React from 'react';
import { Typewriter, useTypewriter, Cursor } from 'react-simple-typewriter'
const Slider=()=>{

    const {text} = useTypewriter({
        words: ['effortless & powerful with easy usage', 'best tools in the world', 'good choice', 'Free and Easy to use'],
        loop: false,
        onLoopDone: () => console.log(`loop completed after 3 runs.`),
      })
        return (
            <section class="wrapper bg-soft-primary">
                <div class="container pt-10 pb-12 pt-md-14 pb-md-17">
                    <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                    <div class="col-md-10 offset-md-1 offset-lg-0 col-lg-5 mt-lg-n2 text-center text-lg-start order-2 order-lg-0" data-group="page-title">
                        <h1 class="display-3 mb-5 mx-md-10 mx-lg-0">Ongsho Tools is {text}</h1>
                        <p class="lead fs-lg mb-7">Achieve your saving goals. Have all your recurring and one time expenses and incomes in one place.
                       
                        </p>
                        <div class="d-flex justify-content-center justify-content-lg-start"  data-group="page-title-buttons" >
                        <span><a class="btn btn-lg btn-primary rounded me-2">Get Started</a></span>
                        <span><a class="btn btn-lg btn-green rounded">Free Trial</a></span>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="row">
                        <div class="col-3 offset-1 offset-lg-0 col-lg-4 d-flex flex-column"  data-group="col-start" data-delay="300">
                            <div class="ms-auto mt-auto"><img class="img-fluid rounded shadow-lg" src="./assets/img/photos/sa20.jpg" srcset="./assets/img/photos/sa20@2x.jpg 2x" alt="" /></div>
                            <div class="ms-auto mt-5 mb-10"><img class="img-fluid rounded shadow-lg" src="./assets/img/photos/sa18.jpg" srcset="./assets/img/photos/sa18@2x.jpg 2x" alt="" /></div>
                        </div>
                        <div class="col-4 col-lg-5" >
                            <div><img class="w-100 img-fluid rounded shadow-lg" src="./assets/img/photos/sa16.jpg" srcset="./assets/img/photos/sa16@2x.jpg 2x" alt="" /></div>
                        </div>
                        <div class="col-3 d-flex flex-column"  data-group="col-end" data-delay="300">
                            <div class="mt-auto"><img class="img-fluid rounded shadow-lg" src="./assets/img/photos/sa21.jpg" srcset="./assets/img/photos/sa21@2x.jpg 2x" alt="" /></div>
                            <div class="mt-5"><img class="img-fluid rounded shadow-lg" src="./assets/img/photos/sa19.jpg" srcset="./assets/img/photos/sa19@2x.jpg 2x" alt="" /></div>
                            <div class="mt-5 mb-10"><img class="img-fluid rounded shadow-lg" src="./assets/img/photos/sa17.jpg" srcset="./assets/img/photos/sa17@2x.jpg 2x" alt="" /></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        )
}
export default Slider