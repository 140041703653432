import React from 'react'
import CopyButton from '../copybutton/copybutton'
import SideButton from '../layouts/textboxes/sidebuttons';
import {Helmet} from 'react-helmet';
import {metaTag } from '../../meta';
class TextToSlug extends React.Component{


    state={
        words:'',
        defaults:''
    }

    componentDidMount(){
        
    }
    
    textToSlug(defaults){
        let wordArr=(defaults.trim()).split(' ');
        let data='';
        wordArr.forEach(e=>{
            if(e!=''){
                data+=e.toLowerCase()+'-';
            }
           })
        this.state.words=(data.slice(0,data.length-1)).replace(/(\r\n|\n|\r)/gm, "");;
        this.forceUpdate();
    }
    handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`; 
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
      }
    render(){
        return (
            <div className='container'>
                {/* <div className="banner-title">
                    Text Case Conversion Tools
                </div> */}
                <Helmet>
                    <title>{metaTag.text_to_slug.title}</title>
                    <meta name="description" content={metaTag.text_to_slug.description} />
                    <meta name="keywords" content={metaTag.text_to_slug.keyword} />
                    {/* meta for facebook */}
                    <meta property="og:locale"  content={metaTag.text_to_slug.meta.facebook.locale}/>
                    <meta property="og:type"  content={metaTag.text_to_slug.meta.facebook.type}/>
                    <meta property="og:title"  content={metaTag.text_to_slug.meta.facebook.title}/>
                    <meta property="og:description"  content={metaTag.text_to_slug.meta.facebook.description}/>
                    <meta property="og:url"  content={metaTag.text_to_slug.meta.facebook.url}/>
                    <meta property="og:site_name"  content={metaTag.text_to_slug.meta.facebook.site_name}/>
                    <meta property="og:image"  content={metaTag.text_to_slug.meta.facebook.image}/>
                    <meta property="og:image:width"  content={metaTag.text_to_slug.meta.facebook.image_width}/>
                    <meta property="og:image:height"  content={metaTag.text_to_slug.meta.facebook.image_height}/>
                    <meta property="og:image:alt"  content={metaTag.text_to_slug.meta.facebook.image_alt}/>
                    <meta property="og:image:type"  content={metaTag.text_to_slug.meta.facebook.image_type}/>
                    {/* meta for twitter */}
                    <meta name="twitter:card" content={metaTag.text_to_slug.meta.twitter.card} />
                    <meta name="twitter:title" content={metaTag.text_to_slug.meta.twitter.title} />
                    <meta name="twitter:description" content={metaTag.text_to_slug.meta.twitter.description} />
                    <meta name="twitter:image" content={metaTag.text_to_slug.meta.twitter.image} />
                    <meta name="twitter:url" content={metaTag.text_to_slug.meta.twitter.url} />
                    <meta name="twitter:site" content={metaTag.text_to_slug.meta.twitter.site} />
                    <meta name="twitter:creator" content={metaTag.text_to_slug.meta.twitter.creator} />
                </Helmet>
                <section class="wrapper bg-soft-primary">
                    <div class="container pt-5 pb-10 pt-md-14 pb-md-10 text-center">
                        <div class="row">
                        <div class="col-md-9 col-lg-7 col-xl-5 mx-auto">
                            <h1 class="display-1 mb-3">Text To Slug Convert</h1>
                            <p class="lead px-xxl-10">Copy any custom Text and paste below .</p>
                        </div>
                        </div>
                    </div>
                </section>
                <div className="row mt-5 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row ">
                            <div className="col-12 col-md-12">
                                <form action="">
                                    <textarea name="" id="words"  rows="5" className="form-control" placeholder="write something..." onChange={(e)=>{this.setState({defaults:e.target.value})}} onKeyDown={this.handleKeyDown}></textarea>
                                </form>
                                <div className="counter">word: {(this.state.defaults !='' ? this.state.defaults.split(" ").length :0)} | Length: {this.state.defaults.length}</div>
                                <div>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1" onClick={()=>this.textToSlug(this.state.defaults)}>Convert</button>
                                </div>
                            </div>
                            <CopyButton words={this.state.words}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <SideButton/>
                    </div>
                </div>
                
            </div>
        )
    }

}
export default TextToSlug