import React from 'react'
import CopyButton from '../copybutton/copybutton'
import SideButton from '../layouts/textboxes/sidebuttons';
import {Helmet} from 'react-helmet';
import {metaTag } from '../../meta';
import { sentenceCase } from "sentence-case";
class CaseConversion extends React.Component{


    state={
        words:'',
        wordCount:'',
        charCount:'',
        defaults:''
    }
    componentDidMount(){
        
    }
    toggleCase(defaults){
        let wordArr=defaults.split(' ');
        let data='';
        wordArr.forEach(t=>{
            t=t.replace(/(?:\r\n|\r|\n)/g, ' '+'\n'+' ').split('');
            console.log(t)
            let word='';
            t.map((letter)=>{
                    word+= (letter.toUpperCase()===letter ? letter.toLowerCase() : letter.toUpperCase());
                    console.log(word)
                });
                data+=word+" ";
            })
        this.state.words=data;
        this.forceUpdate();
    }
    sentenceCase(defaults){
         let newString = defaults.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()});
        // let newString= sentenceCase(defaults);
         newString=newString.split(/\n/g)
                .map((x) =>{
                        x=x.trim();
                      return  x.charAt(0).toUpperCase() + x.substr(1);
                    }).join("\n")
       
        this.state.words=newString;
        this.forceUpdate();
    }
    capitalizeCase(defaults){
        let wordArr=defaults.split(' ');
        let data='';
        // console.log(wordArr);
        
        wordArr.forEach((t)=>{
                t=t.replace(/(?:\r\n|\r|\n)/g, ' '+'\n'+' ').split(' ');
                console.log(t)
                t.forEach((e)=>{
                    data+=e.charAt(0).toUpperCase()+(e.slice(1)).toLowerCase()+' ';
                })
           });
        this.state.words=data;
        this.forceUpdate();
    }
    normalCase(defaults){
        this.state.words=defaults;
        this.forceUpdate()
    }
    lowerCase(defaults){
        this.state.words=defaults.toLowerCase();
        this.forceUpdate()
    }
    upperCase(defaults){
        this.state.words=defaults.toUpperCase();
        this.forceUpdate()
    }
    titleCase(defaults){
        // console.log('noman')
       let preposition=["aboard","about","above","across","after","against","along","amid","among","anti","around","as","at", "before","behind","below","beneath","beside","besides","between","beyond","but","by","concerning", "considering","despite","down","during","except","excepting","excluding","following","for","from","in","inside","into","like","minus","near","of","off","on","onto","opposite","outside","over","past","per","plus","regarding","round","save","since","than","through","to","toward","towards","under","underneath","unlike","until","up","upon","versus","via","with","within","without"];
       let wordArr=defaults.split(' ');
       let data='';
       wordArr.forEach(t=>{
        t=t.replace(/(?:\r\n|\r|\n)/g, ' '+'\n'+' ').split(' ');
        console.log(t)
        t.forEach(e=>{
            if(preposition.includes(e.toLowerCase())){
                data+=e.toLowerCase()+' ';
            }else{
                data+=e.charAt(0).toUpperCase()+(e.slice(1)).toLowerCase()+' '
            }
        })
        
       })
       this.state.words=data;
       this.forceUpdate()
    }
    textToSlug(defaults){
        let wordArr=defaults.split(' ');
        let data='';
        wordArr.forEach(e=>{
            if(e!=''){
                data+=e.toLowerCase()+'-';
            }
           });
        this.state.words=data.slice(0,data.length-1);
        this.forceUpdate();
    }
    render(){
        return (
            <div className='container'>
                {/* <div className="banner-title">
                    Text Case Conversion Tools
                </div> */}
                <Helmet>
                    <title>{metaTag.case_converter.title}</title>
                    <meta name="description" content={metaTag.case_converter.description} />
                    <meta name="keywords" content={metaTag.case_converter.keyword} />
                    {/* meta for facebook */}
                    <meta property="og:locale"  content={metaTag.case_converter.meta.facebook.locale}/>
                    <meta property="og:type"  content={metaTag.case_converter.meta.facebook.type}/>
                    <meta property="og:title"  content={metaTag.case_converter.meta.facebook.title}/>
                    <meta property="og:description"  content={metaTag.case_converter.meta.facebook.description}/>
                    <meta property="og:url"  content={metaTag.case_converter.meta.facebook.url}/>
                    <meta property="og:site_name"  content={metaTag.case_converter.meta.facebook.site_name}/>
                    <meta property="og:image"  content={metaTag.case_converter.meta.facebook.image}/>
                    <meta property="og:image:width"  content={metaTag.case_converter.meta.facebook.image_width}/>
                    <meta property="og:image:height"  content={metaTag.case_converter.meta.facebook.image_height}/>
                    <meta property="og:image:alt"  content={metaTag.case_converter.meta.facebook.image_alt}/>
                    <meta property="og:image:type"  content={metaTag.case_converter.meta.facebook.image_type}/>
                    {/* meta for twitter */}
                    <meta name="twitter:card" content={metaTag.case_converter.meta.twitter.card} />
                    <meta name="twitter:title" content={metaTag.case_converter.meta.twitter.title} />
                    <meta name="twitter:description" content={metaTag.case_converter.meta.twitter.description} />
                    <meta name="twitter:image" content={metaTag.case_converter.meta.twitter.image} />
                    <meta name="twitter:url" content={metaTag.case_converter.meta.twitter.url} />
                    <meta name="twitter:site" content={metaTag.case_converter.meta.twitter.site} />
                    <meta name="twitter:creator" content={metaTag.case_converter.meta.twitter.creator} />
                </Helmet>
                <section class="wrapper bg-soft-primary">
                    <div class="container pt-5 pb-10 pt-md-14 pb-md-10 text-center">
                        <div class="row">
                        <div class="col-md-9 col-lg-7 col-xl-5 mx-auto">
                            <h1 class="display-1 mb-3">Text Convert</h1>
                            <p class="lead px-xxl-10">Copy any custom Text and paste below .</p>
                        </div>
                        </div>
                    </div>
                </section>
                <div className="row  mt-5 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <form action="">
                                    <textarea name="" id="words"  rows="5" className="form-control" placeholder="write something..." onChange={(e)=>{this.setState({defaults:e.target.value})}} onKeyDown={this.handleKeyDown}></textarea>
                                </form>
                                <div className="counter">word: {(this.state.defaults !='' ? this.state.defaults.split(" ").length :0)} | Length: {this.state.defaults.length}</div>
                                <div>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1 p-1 px-2" onClick={()=>this.sentenceCase(this.state.defaults)}>Sentence case</button>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1 p-1 px-2" onClick={()=>this.capitalizeCase(this.state.defaults)}>Capitalize Case</button>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1 p-1 px-2" onClick={()=>this.upperCase(this.state.defaults)}>UPPER CASE</button>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1 p-1 px-2" onClick={()=>this.lowerCase(this.state.defaults)}>lower case</button>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1 p-1 px-2" onClick={()=>this.titleCase(this.state.defaults)}>Title Case</button>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1 p-1 px-2" onClick={()=>this.toggleCase(this.state.defaults)}>Toggle Case</button>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1 p-1 px-2" onClick={()=>this.normalCase(this.state.defaults)}>Normal Case</button>
                                </div>
                            </div>
                            <CopyButton words={this.state.words}/>
                        
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <SideButton/>
                    </div>
                </div>
                
            </div>
        )
    }

}
export default CaseConversion