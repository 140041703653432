
// 'use strict';;
import React from 'react';
import CopyButton from '../copybutton/copybutton'
import SideButton from '../layouts/textboxes/sidebuttons';
import {Helmet} from 'react-helmet';
import {metaTag } from '../../meta';
class HexToBinary extends React.Component{


    state={
        selectedFile:null,
        words:'',
        defaults:'',
    }

    componentDidMount(){
        
    }
    hexToBinary(hex){
        hex = hex.replace("0x", "").toLowerCase();
        var out = "";
        
        for(var c of hex) {
            switch(c) {
                case '0': out += "0000"; break;
                case '1': out += "0001"; break;
                case '2': out += "0010"; break;
                case '3': out += "0011"; break;
                case '4': out += "0100"; break;
                case '5': out += "0101"; break;
                case '6': out += "0110"; break;
                case '7': out += "0111"; break;
                case '8': out += "1000"; break;
                case '9': out += "1001"; break;
                case 'a': out += "1010"; break;
                case 'b': out += "1011"; break;
                case 'c': out += "1100"; break;
                case 'd': out += "1101"; break;
                case 'e': out += "1110"; break;
                case 'f': out += "1111"; break;
                default: return "Wrong input given";
            }
        }
    
        return out;
    }
    convert(defaults){
        if(defaults!=''){
            let text= this.hexToBinary(defaults);
            this.state.words=text.toString();
            console.log(text)
            this.forceUpdate();
        }
    }
    handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`; 
        // In case you have a limitation
        // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    }
    onFileChange = event => {
    
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
        console.log(event.target.files[0]);
    };
    textCopy(text){
        navigator.clipboard.writeText(text);
    }
    render(){
        return (
            <div className='container'>
                {/* <div className="banner-title">
                    Text Case Conversion Tools
                </div> */}
                <Helmet>
                    <title>{metaTag.hex_to_binary.title}</title>
                    <meta name="description" content={metaTag.hex_to_binary.description} />
                    <meta name="keywords" content={metaTag.hex_to_binary.keyword} />
                    {/* meta for facebook */}
                    <meta property="og:locale"  content={metaTag.hex_to_binary.meta.facebook.locale}/>
                    <meta property="og:type"  content={metaTag.hex_to_binary.meta.facebook.type}/>
                    <meta property="og:title"  content={metaTag.hex_to_binary.meta.facebook.title}/>
                    <meta property="og:description"  content={metaTag.hex_to_binary.meta.facebook.description}/>
                    <meta property="og:url"  content={metaTag.hex_to_binary.meta.facebook.url}/>
                    <meta property="og:site_name"  content={metaTag.hex_to_binary.meta.facebook.site_name}/>
                    <meta property="og:image"  content={metaTag.hex_to_binary.meta.facebook.image}/>
                    <meta property="og:image:width"  content={metaTag.hex_to_binary.meta.facebook.image_width}/>
                    <meta property="og:image:height"  content={metaTag.hex_to_binary.meta.facebook.image_height}/>
                    <meta property="og:image:alt"  content={metaTag.hex_to_binary.meta.facebook.image_alt}/>
                    <meta property="og:image:type"  content={metaTag.hex_to_binary.meta.facebook.image_type}/>
                    {/* meta for twitter */}
                    <meta name="twitter:card" content={metaTag.hex_to_binary.meta.twitter.card} />
                    <meta name="twitter:title" content={metaTag.hex_to_binary.meta.twitter.title} />
                    <meta name="twitter:description" content={metaTag.hex_to_binary.meta.twitter.description} />
                    <meta name="twitter:image" content={metaTag.hex_to_binary.meta.twitter.image} />
                    <meta name="twitter:url" content={metaTag.hex_to_binary.meta.twitter.url} />
                    <meta name="twitter:site" content={metaTag.hex_to_binary.meta.twitter.site} />
                    <meta name="twitter:creator" content={metaTag.hex_to_binary.meta.twitter.creator} />
                </Helmet>
                <section class="wrapper bg-soft-primary">
                    <div class="container pt-5 pb-10 pt-md-14 pb-md-10 text-center">
                        <div class="row">
                        <div class="col-md-9 col-lg-7 col-xl-5 mx-auto">
                            <h1 class="display-1 mb-3">Hex To Binary</h1>
                            <p class="lead px-xxl-10">Write any custom Binary Digit and convert to Text</p>
                        </div>
                        </div>
                    </div>
                </section>
                <div className="row  mt-5 mb-5">
                    <div className="col-12 col-md-9">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <form action="">
                                    <textarea name="" id="words"  rows="5" className="form-control" placeholder="write something..." onChange={(e)=>{this.setState({defaults:e.target.value})}} onKeyDown={this.handleKeyDown}></textarea>
                                </form>
                                <div className="counter">word: {(this.state.defaults !='' ? this.state.defaults.split(" ").length :0)} | Length: {this.state.defaults.length}</div>
                                <div>
                                    <button className="btn btn-sm btn-primary mb-1 mr-1" onClick={()=>this.convert(this.state.defaults)}>Convert</button>
                                </div>

                            </div>
                            <CopyButton words={this.state.words}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <SideButton/>
                    </div>
                </div>
                
            </div>
        )
    }

}
export default HexToBinary